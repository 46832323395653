// export const ifFileExists = (image_url) => {

//   var http = new XMLHttpRequest();

//   http.open("HEAD", image_url, false);
//   http.send();

//   return http.status != 404;
// };

export const getThumbnailUrl = (thumbnail) => {
  return `${process.env.VUE_APP_CDN}` + "/" + thumbnail.cdnUrl;
};

export const getCssThumbnail = (thumbnail) => {
  return {
    "background-image": `url(${getThumbnailUrl(thumbnail)}.webp)`,
  };
};
